<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="toggleTable">
			<a-tabs default-active-key="1" @change="changeTab">
				<a-tab-pane key="1" tab="Pending"></a-tab-pane>
				<a-tab-pane key="2" tab="All" force-render> </a-tab-pane>
			</a-tabs>
		</template>
		<template slot="scopedSlots" slot-scope="{ columnData }">
			<span>{{ columnData.recommendAmountType }}</span>
			<a-statistic :precision="0" :value="columnData.recommendAmount === null ? ' - ' : columnData.recommendAmount" />
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a @click="checkDetail(columnData.loanId)">{{ $t('page.checkSubmit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiEmergencyApplyCheck } from '@/api/emergencyLoan'
import { apiBranchList } from '@/api/branch'
export default {
	data() {
		return {
			unprocessed: true,
			branchOfficeList: []
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.applyInfo.cloums.creator',
					type: 'input',
					validateField: ['creator']
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [] }]
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				// {
				// 	title: this.$t('table.applyInfo.cloums.caseStatus'),
				// 	dataIndex: 'statusCode',
				// 	align: 'center',
				// 	customRender: (v, o) => {
				// 		return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
				// 	}
				// },
				// {
				// 	title: this.$t('table.applyInfo.loanApplyLabel.branchOffice'),
				// 	dataIndex: 'storeName',
				// 	align: 'center'
				// },
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'regNo',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.loanAmountRequested'),
					dataIndex: 'applyAmount',
					align: 'center',
					customRender: (v, o) => {
						return <a-statistic precision={0} value={o.applyAmount === null ? ' - ' : o.applyAmount} />
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.loanApproved'),
					align: 'center',
					scopedSlots: { customRender: 'scopedSlots' }
				},
				{
					title: this.$t('table.applyInfo.cloums.creator'),
					dataIndex: 'creatorName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.createTime'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.submiter'),
					dataIndex: 'submitUserName',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.submitTime'),
					dataIndex: 'submitTime',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.nextReviewer'),
					dataIndex: 'nextCheckers',
					align: 'center',
					width: 200,
					customRender: (v, o) => {
						return v ? v.join(',') : '-'
					}
				},
				{
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 150,
					fixed: 'right'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiEmergencyApplyCheck({ ...initParams, unprocessed: this.unprocessed })
		},
		changeTab(key) {
			this.unprocessed = key === '1'
			this.$refs.searchListTable.initTable(1)
		},
		checkDetail(id) {
			this.$router.push({
				path: 'detail',
				query: {
					id,
					from: this.$route.path
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
